import React from "react"
import { ThemeProvider } from "@material-tailwind/react"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"
import { SUPPORTED_LOCALES } from "../utils/constants"

const theme = {
  breadcrumbs: {
    styles: {
      base: {
        root: {
          initial: {
            width: "w-full",
            margin: "mx-auto",
          },
        },
        list: {
          flexWrap: "flex-wrap md:flex-nowrap",
        },
        item: {
          initial: {
            whiteSpace: "whitespace-nowrap",
            truncate: "truncate",
            padding: "pr-0 md:pr-6 lg:pr-0",
          },
        },
      },
    },
  },
}

export const AppProviders = ({ children }) => {
  const { i18n } = useTranslation()

  const changeLanguage = lng => {
    Cookies.set("i18next", lng, { expires: 7 })

    i18n.changeLanguage(lng)
  }

  if (i18n.language.includes("-")) {
    var locale = i18n.language.split("-")[0]
    if (SUPPORTED_LOCALES.includes(locale)) {
      changeLanguage(locale)
    } else {
      changeLanguage("en")
    }
  } else if (i18n.language === "") {
    changeLanguage("pt")
  }

  return <ThemeProvider value={theme}>{children}</ThemeProvider>
}
