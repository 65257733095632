export const COUNTRY_CODE = {
  en: "us",
  pt: "br",
  es: "es",
}

export const SUPPORTED_LOCALES = ["pt", "en"]

export const TELEGRAM_LINK = "https://t.me/digitracom"
export const DISCORD_LINK = "https://discord.gg/digitra-com-1051260382536269854"
export const INSTAGRAM_LINK = "https://www.instagram.com/digitracom/"
export const LINKEDIN_LINK = "https://www.linkedin.com/company/digitra-global/"
export const TWITTER_LINK = "https://twitter.com/Digitra_com"
export const FACEBOOK_LINK = "https://www.facebook.com/DigitraGlobal/"
export const YOUTUBE_LINK = "https://www.youtube.com/@digitracom"
export const DGTA_WHITEPAPER_LINK = "https://digitra-legal.s3.amazonaws.com/public/DGTA_LitePaper.pdf"
export const DIGITRA_API_DOCS = "https://digitracom.github.io/docs/"
export const REDDIT_LINK = "https://www.reddit.com/r/Digitra_com/"
export const OPEN_ACCOUNT_LINK = "https://digitra.page.link/openaccount"