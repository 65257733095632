export const ptTranslation = {
  learn: "Aprenda",
  market: "Mercado",
  analysis: "Análises",
  helpCenter: "Help Center",
  homePage: "Página Inicial",
  moreContentOnTheMedias: "Mais conteúdos nas redes:",
  articlesThatWillInterest: "Artigos que vão te interessar:",
  seeMore: "Ver mais",
  seeAll: "Ver tudo",
  loadMore: "Carregar Mais",
  noMoreResults: "Não há mais resultados",
  enterYourSearchHere: "Digite sua busca aqui...",
  theZeroFeeExchange: "A exchange *sem taxas.**",
  openYourAccount: "Abra sua conta",
  findHere: "Encontre Aqui",
  custodyInsurence: "*Guarda Segura*",
  yourAssetsProtected: "Seus ativos protegidos com a #Fireblocks#, referência global em custódia de cripto",
  fastTradingSpeeds: "Negociações mais rápidas sem cobrar nenhuma taxa.",
  apiDocumentation: "Documentação de API",
  allTradesBecomeRewards:
    "Todas os trades se tornam  recompensas em DGTA. E você ganha duas vezes: por número  de traders e volume negociado na plataforma na dia.",
  builtInAuthenticator: "Autenticador integrado",
  noMoreMultipleApps:
    "Tudo em um único aplicativo, o acesso à sua conta agora é fácil.",
  faceIdAsKey: "Selfie de segurança",
  youAreTheOnlyKey:
    "Usamos um sofisticado sistema biométrico. Você é a única chave para seu cofre.",
  exploreTheRevolution: "Explore a revolução.",
  getToKnowDigitra: "Conheça a digitra.com",
  allAroundTheWorld: "No mundo todo",
  digitraImpactsFinancial:
    "O Digitra.com impacta os mercados financeiros em todo o mundo e, é claro, está no coração de Wall Street.",
  learnMoreAboutDigitra: "Saiba mais sobre a Digitra",
  joinUs: "Entre na comunidade!",
  giveAwaysFirstHandNews:
    "Brindes, notícias em primeira mão, insights de mercado e dicas sobre nossos serviços.",
  connectWithTheCommunity:
    "Tudo sobre nossos produtos e serviços, diretamente com o time de especialistas.",
  lotsOfContentAboutUs:
    "Fale com a comunidade cripto, aprenda de um jeito simples, em tempo real com o mercado.",
  freshContenteMadeByOurExperts:
    "Conteúdo feito por nossos especialistas, prático e direto ao ponto.",
  weSpecializeInServing:
    "Somos especializados em atender clientes institucionais e cidadãos de alta renda que operam grandes ordens.",
  ourFocusIsOnProviding:
    "Nosso foco está em proporcionar uma experiência única e personalizada, adaptada às suas necessidades.",
  withOurOtcDesk:
    "Com o nosso OTC, espere serviços de execução e liquidação seguros, competitivos e confidenciais.",
  sendEmailTo: "Envie um e-mail para",
  withTheContactOfYourChoice:
    "com o contato de sua preferência (e-mail ou whatsapp) para receber ajuda de nosso especialista.",
  howOtcTradingCanUnleash:
    "Como as operações em OTC aumentam seu potencial financeiro?",
  itsSoSimple: "É tão simples!",
  ourClientsAcrossTheGlobe:
    "Nossos clientes em todo o mundo desfrutam de  liquidez incomparáveis.",
  withExclusiveNetworkRates:
    "Com taxas exclusivas de redes, eliminanos gastos desnecessários.",
  receiveSupportFromSpecialists:
    "Receba suporte de especialistas em todas as etapas da jornada.",
  talkToOneOfOurSpecialists: "Fale com um especialista hoje",
  stepByStep: "Nosso método",
  reachOutToUsNow: "Entre em contato",
  fillInTheFormToStart:
    "Preencha o formulário para iniciar nossa jornada juntos com um dos nossos especialistas.",
  insideTheAction: "Por dentro da ação",
  usingTheChannelOfYourChoice:
    "Usando o canal de sua escolha, você acompanhará a experiência do trading, com os spreads, taxas e fornecedores do mundo todo.",
  theFinishLine: "A linha de chegada",
  OurSkilledTrader:
    "Nossos traders habilidosos encontrarão o melhor preço - sempre - e assim que recebermos seus fundos, fechamos o negócio.",
  transparencyCompliance: "Transparência e Compliance",
  wellTransferTheDesiredAsset:
    "Transferimos o ativo para o endereço de sua carteira escolhida ou guardamos em sua conta aqui conosco, onde você tem seguro contra ataques.",
  createAccount: "Crie sua conta",
  digitraAuthenticator: "Autenticador Digitra.com",
  helpCenterPage: "Central de Ajuda",
  privacyCookie: "Política de Privacidade e Cookies",
  termsUse: "Termos de Uso",
  feesLimits: "Taxas e Limites",
  begginer: "iniciante",
  intermediate: "intermediário",
  advanced: "avançado",
  tradeToEarnExclusivity: "Exclusividade Trade to Earn!",
  pageNotFound: "Página não encontrada",
  zeroFeeWithDGTA: "* Para contas com, no mínimo, 2500 DGTAs de saldo disponível.",
  digitraApplicableLaw: "A Digitra.com está em conformidade com todas as leis e regulamentos aplicáveis no Brasil. Em atendimento ao disposto no art. 41 da Lei n° 13.709 de 2018 (Lei Geral da Proteção de Dados), a Digitra.com designa como encarregado pelo tratamento dos dados: Vanessa Rodrigues.",
  openAndEarn: "Abra sua conta e ganhe tokens *DGTA*",
  openMyAccount: "ABRIR MINHA CONTA!",
  globalExchange: "Corretora de #criptomoedas# presente em mais de 170 países",
  aboutDGTA: "Token ERC-20 nativo da digitra.com oferece recompensas diárias no programa #Trade to Earn#.",
  aboutTradeToEarn: "Ganhe cashback em DGTA por cada trade realizado, todos os dias. \n#Simples#: comprou ou vendeu alguma moeda, ganhou DGTA. Acumule tokens diariamente!",
  learnMore: "Saiba mais!",
  zeroFee: "*Taxa Zero*",
  zeroFeeExplanation: "Detentores do token DGTA garantem taxa zero em #todos os pares#.",
  api: "*API*",
  goToDocumentation: "Acessar documentação",
  apiText: "Negociações mais #rápidas# e automatizadas",
  feePageTitle: "Política de Taxas da digitra.com",
  tradeFeeSubtitle: "Taxa de negociação",
  withdrawFeeSubtitle: "Taxa de saque",
  withdrawFeeParagraph: "A digitra.com não cobra taxas em saques de moedas fiat, como o Real brasileiro, somente em saques de criptomoedas, cobradas quando você quiser retirar uma criptomoeda da digitra.com, podendo transferir para outra exchange ou wallet de cripto.",
  howDoesItWork: "Como funciona?",
  withdrawFeeExplanation: "A taxa de saque se refere à taxa da rede blockchain e a serviços operacionais. A taxa de rede *varia de acordo com a blockchain* usada para a transferência. A taxa de serviço corresponde a *20% sobre o valor da taxa de rede*, ou seja, o valor também dependerá da rede que você escolher para sacar.",
  withdrawFeeImportant: "*Importante:* para todas as criptomoedas, a taxa mínima de saque é de *$1*. Veja os exemplos abaixo:",
  tradeFeeWithoutMinDGTA: "A taxa em transações de compra ou venda com qualquer par de ativos disponíveis na digitra.com é de 0,1% para market maker e 0,5% para market taker. A cobrança de taxa nesse caso será a seguinte:",
  tradeFeeWithoutMinBullet: "*0,1% ou 0,5% sob o volume negociado na transação — valor cobrado em cima da moeda que será creditada em sua conta*",
  tradeFeeExample: "*Ex:* Se você tiver um saldo de 10 dólares e usá-lo para comprar Bitcoin, a taxa será cobrada em cima do crédito em Bitcoin. Assim que a compra for concluída, o seu saldo de Bitcoin já estará na carteira com o valor da taxa descontado.",
  examples: "Exemplos",
  crypto: "Criptomoeda",
  blockchainFee: "Taxa de rede",
  totalFee: "Taxa total",
  cryptoCase1: "Você está sacando Ethereum ETH, usando a rede ERC-20",
  cryptoCase2: "Você está sacando MATIC na rede Polygon",
  blockchainFeeCase1: "A taxa de rede no momento do saque está em 0.003253 ETH, equivalente a $7",
  blockchainFeeCase2: "A taxa de rede no momento do saque está em 0.567774 MATIC, equivalente a $0,50",
  totalFeeCase1: "A taxa total irá incluir os 20% de serviço referente a taxa de rede, totalizando $8,4",
  totalFeeCase2: "A taxa total ficará no valor mínimo de $1",
  minDGTAZeroFeeLink: "200 DGTA tokens",
  seeMoreAbountTradeFee: "Veja aqui mais detalhes da Taxa Zero em negociações!",
  tradingFeeWithMinDGTAStart: "Os clientes que mantém, no mínimo,",
  inYourWallet: ", em sua carteira digitra.com não pagam taxas de negociação à vista.",
  fees: "Taxas",
  makerAndTaker: "O que é Market Maker e Market Taker?",
  makerAndTakerResume: "No contexto do mercado de criptomoedas, os termos *\"maker\"* e *\"taker\"* referem-se a dois participantes distintos no processo de negociação. O market maker, ou criador de mercado, é aquele que coloca as ordens a limite, definindo previamente os valores dos ativos para compra ou venda. Sua função é criar liquidez no mercado, disponibilizando ordens que aguardam correspondência. Por outro lado, o market taker, ou tomador de mercado, é quem aceita as ordens previamente estabelecidas pelo market maker. O papel do taker é executar ordens de mercado, atendendo às solicitações de compra ou venda com base nos preços atuais do ativo.",
  makerAndTakerOrderTypes: "Para compreender melhor esses papéis, é necessário compreender as diferenças entre ordens de mercado e ordens a limite. As ordens de mercado são executadas com base nos preços atuais do ativo, enquanto as ordens a limite têm seus valores pré-estabelecidos pelo comprador ou vendedor. Portanto, o market maker é o responsável por criar ordens a limite, contribuindo para a formação da livro de ofertas de criptomoedas, enquanto o market taker utiliza ordens de mercado para realizar transações. Essa distinção não apenas influencia o processo de negociação, mas também impacta as taxas cobradas pelos serviços, destacando a importância desses dois participantes no ecossistema das criptomoedas.",
  cryptoDividends: "Dividendos Cripto",
  cryptoDividendsText: "Traga suas criptomoedas e receba renda passiva todo mês.",
  openAccount: "Abrir conta",
  liquidityProvider: "Provedor de Liquidez",
  liquidityProviderText: "Suas ordens de compra ou venda de criptomoedas geram rendimentos.",
  readLitePaper: "Leia o Litepaper!",
  liquidityLitepaper: "Litepaper Provedor de Liquidez"
}
