export const esTranslation = {
  learn: "Aprende",
  market: "Mercado",
  analysis: "Análisis",
  helpCenter: "Help Center",
  homePage: "Inicio",
  moreContentOnTheMedias: "Más contenido en las redes:",
  articlesThatWillInterest: "Artículos que te interesarán:",
  seeMore: "Ver más",
  seeAll: "Ver todo",
  loadMore: "Cargar Más",
  noMoreResults: "No hay más resultadoss",
  enterYourSearchHere: "Escribe aquí tu búsqueda...",
  theZeroFeeExchange: "El intercambio *sin comisiones.**",
  openYourAccount: "Abre tu cuenta",
  findHere: "Encuentre aqui",
  custodyInsurence: "*Custodia con* #seguro#",
  yourAssetsProtected: "Tus activos protegidos contra pérdidas y ataques.",
  fastTradingSpeeds: "Operaciones más rápidas sin cobrar comisiones.",
  apiDocumentation: "Documentación API",
  allTradesBecomeRewards:
    "Todos los trades se convierten en recompensas en DGTA. Y gana dos veces: por número de traders y volumen negociado ese día.",
  builtInAuthenticator: "Autenticador integrado",
  noMoreMultipleApps:
    "Todo en una sola aplicación, el acceso a su cuenta ahora es fácil.",
  faceIdAsKey: "Selfie de Seguridad",
  youAreTheOnlyKey:
    "Utilizamos un sistema biométrico sofisticado. Tú eres la única llave de tu caja fuerte.",
  exploreTheRevolution: "Explore la revolución.",
  getToKnowDigitra: "Conoce digitra.com",
  allAroundTheWorld: "En todo el mundo",
  digitraImpactsFinancial:
    "Digitra.com tiene un impacto en los mercados financieros de todo el mundo y, por supuesto, está en el corazón de Wall Street.",
  learnMoreAboutDigitra: "Conozca más respecto de Digitra",
  joinUs: "¡Únete a nuestra comunidad!",
  giveAwaysFirstHandNews:
    "Sorteos, noticias de primera mano, insights de mercado y tips sobre nuestros servicios.",
  connectWithTheCommunity:
    "Todo sobre nuestros productos y servicios, directamente de nuestro equipo de expertos.",
  lotsOfContentAboutUs:
    "Habla con la comunidad cripto, aprende de forma sencilla, en tiempo real con el mercado.",
  freshContenteMadeByOurExperts:
    "Contenido hecho por nuestros expertos, práctico y directo al grano.",
  weSpecializeInServing:
    "Nos especializamos en servir a clientes institucionales y personas de alto poder adquisitivo que necesitan cumplir con grandes pedidos.",
  ourFocusIsOnProviding:
    "Nuestro enfoque es proporcionar una experiencia privada y personalizada adaptada a sus necesidades específicas.",
  withOurOtcDesk:
    "Con nuestra mesa OTC, espere servicios de liquidación y ejecución seguros, competitivos y confidenciales.",
  sendEmailTo: "Envíe un correo electrónico a",
  withTheContactOfYourChoice:
    "con el contacto de su elección (email o whatsapp) para recibir ayuda de nuestro especialista.",
  howOtcTradingCanUnleash:
    "¿Cómo el trading OTC puede liberar su potencial financiero?",
  itsSoSimple: "¡Es tan simple!",
  ourClientsAcrossTheGlobe:
    "Nuestros clientes en todo el mundo experimentan garantías de liquidez sin precedentes.",
  withExclusiveNetworkRates:
    "Con tarifas exclusivas de red, eliminamos gastos innecesarios.",
  receiveSupportFromSpecialists:
    "Reciba apoyo de especialistas en cada etapa de su viaje comercial.",
  talkToOneOfOurSpecialists: "Hablar con un especialista hoy",
  stepByStep: "Nuestro metodo",
  reachOutToUsNow: "Entra en contacto",
  fillInTheFormToStart:
    "Complete el formulario para comenzar nuestro viaje junto con uno de nuestros expertos.",
  insideTheAction: "Dentro de la acción",
  usingTheChannelOfYourChoice:
    "Rellena el formulario para empezar nuestro viaje juntos. Uno de nuestros especialistas se pondrá en contacto pronto.",
  theFinishLine: "La linea final",
  OurSkilledTrader:
    "Nuestros comerciantes calificados encontrarán el mejor precio, siempre, y tan pronto como recibamos sus fondos, cerraremos el trato.",
  transparencyCompliance: "Transparencia y Compliance",
  wellTransferTheDesiredAsset:
    "Transferiremos el activo deseado a la dirección de su billetera elegida o lo guardaremos en su cuenta aquí con nosotros, donde tiene seguridad contra ataques.",
  createAccount: "Cree su cuenta",
  digitraAuthenticator: "Digitra.com Autenticador",
  helpCenterPage: "Centro de ayuda",
  privacyCookie: "Política de privacidad y cookies",
  termsUse: "Términos de Uso",
  feesLimits: "Tarifas y límites",
  begginer: "principiante",
  intermediate: "intermedio",
  advanced: "avanzado",
  tradeToEarnExclusivity: "Trade to Earn exclusividad!",
  pageNotFound: "Página no encontrada",
  zeroFeeWithDGTA: "* Para cuentas con un saldo mínimo de 2500 tokens DGTA.",
  digitraApplicableLaw: "Digitra.com cumple con todas las leyes y regulaciones aplicables en Brasil. De acuerdo con lo establecido en el artículo 41 de la Ley n° 13.709 de 2018 (Ley General de Protección de Datos), Digitra.com designa a Vanessa Rodrigues como responsable del tratamiento de datos.",
  openAndEarn: "Regístrate ahora y obtén tokens *DGTA*",
  openMyAccount: "ABRE TU CUENTA",
  globalExchange: "Exchange de #criptomonedas#, presente en más de 170 países.",
  aboutDGTA: "Nuestro token nativo ERC-20 de digitra.com ofrece recompensas diarias en el programa #Trade to Earn#.",
  aboutTradeToEarn: "Obtén cashback en DGTA por cada operación realizada, todos los días. \n#Simple#: compra o vende cualquier moneda y gana DGTA. ¡Acumula tokens diariamente!",
  learnMore: "¡Más información!",
  zeroFee: "*Tarifa Cero*",
  zeroFeeExplanation: "Los titulares del token DGTA disfrutan de tarifas cero en #todos los pares#.",
  api: "*API*",
  goToDocumentation: "Ver documentación",
  apiText: "Operaciones más #rápidas# y automatizadas",
  feePageTitle: "Política de tarifas de Digitra.com",
  tradeFeeSubtitle: "Tarifa de negociación",
  withdrawFeeSubtitle: "Tarifa de retiro",
  withdrawFeeParagraph: "Digitra.com no cobra tarifas por retiros de moneda fiduciaria, como el Real brasileño, solo por retiros de criptomonedas cuando desea transferir una criptomoneda desde digitra.com, lo que le permite transferirla a otra bolsa o billetera de criptomonedas. Esta es una práctica estándar en los intercambios de criptomonedas.",
  howDoesItWork: "¿Cómo funciona?",
  withdrawFeeExplanation: "La tarifa de retiro se refiere a la tarifa de la red blockchain y a los servicios operativos. La tarifa de red varía según la blockchain utilizada para la transferencia. La tarifa de servicio corresponde al *20% del valor de la tarifa de red*, es decir, el monto también dependerá de la red que elija para el retiro.",
  withdrawFeeImportant: "*Importante:* para todas las criptomonedas, la tarifa mínima de retiro es de *$1*. Vea los ejemplos a continuación:",
  tradeFeeWithoutMinDGTA: "La tarifa por negociar cualquier par de criptomonedas en digitra.com es del 0.1% para creadores de mercado y del 0.5% para tomadores de mercado. La tarifa se cobra de la siguiente manera:",
  tradeFeeWithoutMinBullet: "*0.1% o 0.5% del volumen negociado en la transacción: la cantidad deducida de la criptomoneda acreditada en tu cuenta.*",
  tradeFeeExample: "*Ejemplo:* Si tienes un saldo de $10 y lo utilizas para comprar Bitcoin, la tarifa se cobrará sobre el Bitcoin acreditado. Una vez que la compra esté completa, tu saldo de Bitcoin estará en tu monedero con el monto de la tarifa deducido.",
  examples: "Ejemplos",
  crypto: "Criptomoneda",
  blockchainFee: "Tarifa de red",
  totalFee: "Tarifa total",
  cryptoCase1: "Está retirando Ethereum (ETH), utilizando la red ERC-20",
  cryptoCase2: "Está retirando MATIC en la red Polygon",
  blockchainFeeCase1: "La tarifa de red en el momento del retiro es de 0.003253 ETH, equivalente a $7",
  blockchainFeeCase2: "La tarifa de red en el momento del retiro es de 0.567774 MATIC, equivalente a $0,50",
  totalFeeCase1: "La tarifa total incluirá el 20% de servicio sobre la tarifa de red, totalizando $8,4",
  totalFeeCase2: "La tarifa total será de $1, el mínimo establecido.",
  minDGTAZeroFeeLink: "200 DGTA tokens",
  seeMoreAbountTradeFee: "¡Consulte aquí más detalles sobre la Tarifa Cero en operaciones!",
  tradingFeeWithMinDGTAStart: "Los clientes que mantengan al menos",
  inYourWallet: " en su billetera digitra.com no pagarán tarifas de operación al contado.",
  fees: "Tarifas",
  makerAndTaker: "¿Qué es un creador de mercado y un tomador de mercado?",
  makerAndTakerResume: "En el contexto del mercado de criptomonedas, los términos *\"maker\"* y *\"taker\"* se refieren a dos participantes distintos en el proceso de negociación. El creador de mercado, responsable de crear liquidez, coloca órdenes límite predefiniendo valores de activos para comprar o vender. Su función implica proporcionar liquidez al mercado ofreciendo órdenes a la espera de coincidencias. Por otro lado, el tomador de mercado acepta órdenes establecidas previamente por el creador de mercado. El papel del tomador es ejecutar órdenes de mercado, respondiendo a solicitudes de compra o venta según los precios actuales de los activos.",
  makerAndTakerOrderTypes: "Para comprender mejor estos roles, es esencial entender las diferencias entre órdenes de mercado y órdenes de límite. Las órdenes de mercado se ejecutan según los precios actuales de los activos, mientras que las órdenes de límite tienen valores predeterminados por el comprador o vendedor. Por lo tanto, el creador de mercado es responsable de crear órdenes de límite, contribuyendo a la formación del libro de pedidos de criptomonedas, mientras que el tomador de mercado utiliza órdenes de mercado para realizar transacciones. Esta distinción no solo influye en el proceso de negociación, sino que también afecta a las tarifas cobradas por los servicios, subrayando la importancia de estos dos participantes en el ecosistema de criptomonedas.",
  cryptoDividends: "Dividendos en Cripto",
  cryptoDividendsText: "Trae tus criptomonedas y obtén ingresos pasivos a cada mes.",
  openAccount: "Registrarse",
  liquidityProvider: "Proveedor de Liquidez",
  liquidityProviderText: "Tus órdenes de compra o venta de criptomonedas generan ingresos.",
  readLitePaper: "¡Lea el Litepaper!",
  liquidityLitepaper: "Litepaper Proveedor de Liquidez"
}
