exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-fees-tsx": () => import("./../../../src/pages/fees.tsx" /* webpackChunkName: "component---src-pages-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-otc-tsx": () => import("./../../../src/pages/otc.tsx" /* webpackChunkName: "component---src-pages-otc-tsx" */),
  "component---src-templates-article-post-tsx": () => import("./../../../src/templates/article-post.tsx" /* webpackChunkName: "component---src-templates-article-post-tsx" */),
  "component---src-templates-listing-articles-tsx": () => import("./../../../src/templates/listing-articles.tsx" /* webpackChunkName: "component---src-templates-listing-articles-tsx" */)
}

