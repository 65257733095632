/**
 * Implement Gatsby's Browser APIs in this file.
 * LINHA CRIADA APENAS PARA DISPARAR O BUILD - 2
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import "./src/styles/custom.css"
import "./src/i18n"
import "/node_modules/flag-icons/css/flag-icons.min.css"
import React from "react"
import { AppProviders } from "./src/components/app-providers"

export const wrapRootElement = ({ element }) => (
  <AppProviders>{element}</AppProviders>
)
