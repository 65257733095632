import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { enTranslation } from "./translations/en"
import { ptTranslation } from "./translations/pt"
import { esTranslation } from "./translations/es"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "cookie",
        "navigator",
        "htmlTag",
        "localStorage",
        "querystring",
        "path",
        "subdomain",
      ],
      lookupCookie: "i18next",
    },
    fallbackLng: "pt",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pt: {
        translation: ptTranslation,
      },
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
  })

export default i18n
